@use "@carbon/react/scss/reset";
@use "@carbon/react/scss/config";
@use "@carbon/react/scss/fonts";
@use "@carbon/react/scss/colors";
@use "@carbon/react/scss/spacing" as *;
@use "@carbon/react/scss/zone";
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/themes";
@use "@carbon/react/scss/theme" as *;
@use "@carbon/react/scss/layout";
@use "@carbon/react/scss/layer";
@use "@carbon/react/scss/grid";
@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/motion";
@use "@carbon/react/scss/components";

// tailwind css
// @import "tailwindcss/base";
*,
:after,
:before {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
@import "tailwindcss/utilities";

body,
html {
  width: 100%;
  height: 100%;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
}

#root {
  height: 100%;
}

input,
textarea,
select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -ms-appearance: none !important;
  -o-appearance: none !important;
  outline: none !important;
  appearance: none !important;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

textarea {
  resize: none;
}

input[type="checkbox"] {
  cursor: pointer;
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
//   transition: background-color 5000s ease-in-out 0s;
//   -webkit-text-fill-color: inherit !important;
// }
// input::-webkit-textfield-decoration-container,
// input::-webkit-contacts-auto-fill-button,
// input::-webkit-credentials-auto-fill-button {
//   visibility: hidden;
// }

a,
a:visited,
a:hover,
a:focus,
a:active {
  text-decoration: none;
}

#render-table .cds--data-table-content {
  flex: 1;
  overflow-y: auto;
}

/* PHONE INPUT STYLE */
.phone-container {
  width: 100%;
  position: relative;
}
.phone-container > div:first-child {
  display: none;
}

.cds--overflow-menu-options::after {
  content: none;
}

// data picker
.cds--date-picker {
  width: 100%;
}
.cds--date-picker .cds--date-picker-container {
  flex: 1;
}
.cds--date-picker .cds--date-picker-container input {
  width: 100%;
}
// .cds--date-picker.cds--date-picker--single .cds--date-picker__input {
//   inline-size: auto;
//   width: 100%;
// }

.data-picker-full .cds--date-picker-input__wrapper > span,
.data-picker-full .cds--date-picker-input__wrapper .cds--date-picker__input {
  inline-size: auto;
  width: 100%;
}

// SCROLLBAR STYLES
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

// ::-webkit-scrollbar-track {
//   border-radius: 100px;
//   background-color: #ccc;
// }

::-webkit-scrollbar-thumb {
  background-color: var(--cds-layer-accent-02, #e0e0e0);
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--cds-layer-accent-hover-02, #e0e0e0);
}
::-webkit-scrollbar-thumb:active {
  background-color: var(--cds-layer-accent-active-02, #e0e0e0);
}

// macOS Scrollbar Styles
// .scrollbar-track::-webkit-scrollbar-track {
//   background-color: #2e3035;
// }

// .scrollbar-thumb::-webkit-scrollbar-thumb {
//   background-color: #676b72;
// }

// .scrollbar-thin::-webkit-scrollbar {
//   width: 4px;
// }

// .scrollbar-thin::-webkit-scrollbar-thumb {
//   border-radius: 3px;
// }

// .scrollbar-thin::-webkit-scrollbar-track {
//   border-radius: 3px;
// }
